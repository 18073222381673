import auth0 from 'auth0-js';
import { navigate } from '@reach/router';

// Defined in .env file
let {
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_AUTH_CLIENT_ID,
  REACT_APP_AUTH_REDIRECT_URI
} = process.env;

export default class Auth {
  auth0 = new auth0.WebAuth({
    domain: REACT_APP_AUTH_DOMAIN,
    clientID: REACT_APP_AUTH_CLIENT_ID,
    redirectUri: REACT_APP_AUTH_REDIRECT_URI,
    responseType: 'token id_token',
    audience: 'https://partner-api.uconvert.co.uk',
    scope: 'openid read:content update:content read:analytics'
  });

  login = () => {
    this.auth0.authorize();
  };

  handleAuthentication = () => {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        navigate('/');
      } else if (err) {
        navigate('/');
        console.log(err);
      }
    });
  };

  setSession = authResult => {
    // Set the time that the Access Token will expire at
    let expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
    // navigate to the home route
    navigate('/');
  };

  logout = () => {
    // Clear Access Token and ID Token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    // navigate to the home route
    navigate('/');
  };

  isAuthenticated = () => {
    // Check whether the current time is past the
    // Access Token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  };
}
