import React from 'react';
import { Redirect } from '@reach/router';
import { Helmet } from 'react-helmet';

export default function Login(props) {
  let authed = props.auth.isAuthenticated();

  if (authed) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <h1>Please login</h1>
      <button onClick={props.auth.login}>Login</button>
    </div>
  );
}
