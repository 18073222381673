import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

export default class AuthCallback extends Component {
  handleAuthentication = () => {
    let { location, auth } = this.props;

    if (/access_token|id_token|error/.test(location.hash)) {
      auth.handleAuthentication();
    }
  };

  componentDidMount() {
    this.handleAuthentication();
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Loading</title>
        </Helmet>
        <h1>Loading...</h1>
      </div>
    );
  }
}
