import React, { Component, Fragment } from 'react';
import { Router, Redirect } from '@reach/router';
import { Helmet } from 'react-helmet';

import Auth from '../../auth';
import Login from '../../routes/login';
import Home from '../../routes/home';
import AuthCallback from '../../routes/auth-callback';

const auth = new Auth();

export default class App extends Component {
  render() {
    return (
      <Fragment>
        <Helmet
          titleTemplate="%s | UConvert Partners"
          defaultTitle="UConvert Partners"
        />
        <Router>
          <Login path="/login" auth={auth} />
          <AuthCallback path="/callback" auth={auth} />
          <ProtectedRoute path="/" component={Home} auth={auth} />
          <ProtectedRoute path="/dashboard" component={Home} auth={auth} />
        </Router>
      </Fragment>
    );
  }
}

function ProtectedRoute({ component: Component, ...props }) {
  if (auth.isAuthenticated()) {
    return <Component {...props} />;
  } else {
    return <Redirect to="/login" noThrow />;
  }
}
