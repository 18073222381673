import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';

import styles from './home.module.scss';

class Home extends Component {
  render() {
    let { auth } = this.props;
    let authed = auth.isAuthenticated();
    let handleClick = authed ? auth.logout : auth.login;
    let btnMessage = authed ? 'Logout' : 'Login';

    return (
      <Fragment>
        <Helmet>
          <title>Dashboard</title>
        </Helmet>
        <div className={styles.app}>
          <header className={styles.header}>
            <p>
              Edit <code>src/App.js</code> and save to reload.
            </p>
            <button className={styles.link} onClick={handleClick}>
              {btnMessage}
            </button>
          </header>
        </div>
      </Fragment>
    );
  }
}

export default Home;
